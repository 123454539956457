import React, { useEffect, useState } from "react"
import { graphql, StaticQuery } from "gatsby"
import withSimpleErrorBoundary from "../util/withSimpleErrorBoundary"

const Notebook = ({ data, file }) => {
  const [innerHTML, setInnerHTML] = useState("")

  useEffect(() => {
    const node = data.nodes.filter((node) => node.fileRelativePath === file)[0]
    const html = document.createElement("html")
    // Quick fix to remove hr tags from markdown content
    let nodeHTML = node.html.replace(/<hr\/>/g, "")
    nodeHTML = `${nodeHTML}
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/katex@0.13.20/dist/katex.min.css" integrity="sha384-cRxb1HsKSl8bTfU9fBcGsjktUfQa6w+fwvkYnU8XjFH4Qg8To1+/9OXv5iRzrKX4" crossorigin="anonymous">
    <script defer src="https://cdn.jsdelivr.net/npm/katex@0.13.20/dist/katex.min.js" integrity="sha384-ov99pRO2tAc0JuxTVzf63RHHeQTJ0CIawbDZFiFTzB07aqFZwEu2pz4uzqL+5OPG" crossorigin="anonymous"></script>
    <script defer src="https://cdn.jsdelivr.net/npm/katex@0.13.20/dist/contrib/auto-render.min.js" integrity="sha384-+XBljXPPiv+OzfbB3cVmLHf4hdUFHlWNZN5spNQ7rmHTXpd7WvJum6fIACpNNfIR" crossorigin="anonymous"
        onload="renderMathInElement(document.body);"></script>`
    html.innerHTML = nodeHTML
    const headers = html.getElementsByTagName("h4")
    for (let header of headers) {
      header.parentElement.setAttribute(
        "id",
        header.innerText.replace(/\s/g, "-"),
      )
    }
    const outputs = html.getElementsByClassName("nteract-display-area-stdout")
    for (let output of outputs) {
      output.innerText = output.innerText.replace(/\n/g, "\n")
    }
    const images = html.getElementsByTagName("img")
    for (let image of images) {
      const imageSrcParts = image.src.split("/")
      const imageName = imageSrcParts[imageSrcParts.length - 1]
      const imageSrc = `/images/${imageName}`
      image.src = imageSrc
    }
    setInnerHTML(html.innerHTML)
  }, [data, file])

  return <div dangerouslySetInnerHTML={{ __html: innerHTML }} />
}

export const query = graphql`
  query {
    allJupyterNotebook {
      nodes {
        fileRelativePath
        html
      }
    }
  }
`

const NotebookWithData = (props) => (
  <StaticQuery
    query={query}
    render={(data) => (
      <Notebook data={data.allJupyterNotebook} file={props.file} />
    )}
  />
)

export default withSimpleErrorBoundary(NotebookWithData)
